import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("@/views/home"),
  },
  {
    path: "/team",
    component: () => import("@/views/home/components/team/index.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
