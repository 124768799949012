// import store from "@/store";

export const options = {
  easing: "ease-in",
  lazy: false,
  force: true,
  onStart: (e) => {
    // store.commit("changeListenScroll", false);
  },
  onDone: (e) => {
    // store.commit("changeListenScroll", true);
  },
  x: false,
  y: true,
};
