import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/reset.css";
import "./assets/css/style.css";
import "amfe-flexible";

// const VueScrollTo = require("vue-scrollto");
import VueScrollTo from "vue-scrollto";

// 屏幕滚动插件
Vue.use(VueScrollTo);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
