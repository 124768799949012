<template>
  <div id="app">
    <Header />
    <router-view />
  </div>
</template>
<script>
import Header from "@/components/header/index.vue";
export default {
  components: { Header },
};
</script>

<style lang="scss">
#app {
  /* prettier-ignore */
  min-width: 1260Px;
}
</style>
