import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    headerActive: 0, // 选项
    // listenScroll: true, // 监听页面滚动
    headerH: 0, // 头部高度
  },
  getters: {},
  mutations: {
    // 选项切换
    changeActive(state, data) {
      state.headerActive = data;
    },
    // 动态计算头部高度
    changeHeaderH(state, data) {
      state.headerH = data;
    },
    // 是否监听页面滚动
    // changeListenScroll(state, data) {
    //   state.listenScroll = data;
    // },
  },
  actions: {},
  modules: {},
});
