<template>
  <div class="header" ref="header">
    <div class="w">
      <div class="logo">
        <a href="/" target="_Self">
          <img class="img-logo1" src="@/static/img/logo/headerlogo.svg" />
        </a>
        <img class="img-logo" src="@/static/img/logo/logo集萃.png" >
      </div>
      <div class="menu">
        <ul>
          <li :class="index === headerActive ? 'active' : ''" v-for="(item, index) in menu" :key="index"
            @click="change(index, item.src)">
            <div>{{ item.name }}</div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { options } from "@/utils/scrollTo";
export default {
  name: "Header",
  data() {
    return {
      menu: [
        {
          src: "#brands",
          name: "品牌介绍",
        },
        {
          src: "#teams",
          name: "创始团队",
        },
      ],
    };
  },
  methods: {
    change(num, el) {
      this.$scrollTo(el, 100, {
        ...options,
        offset: -this.$store.state.headerH,
      });
      this.$store.commit("changeActive", num);
    },
  },
  computed: {
    ...mapState(["headerActive"]),
  },
  mounted() {
    this.$store.commit("changeHeaderH", this.$refs.header.offsetHeight);
  },
};
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  height: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;

  .w {
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 100%;
      // height: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      a{
        width: 45%;
      }
      .img-logo{
        width: 10vw;
        height: 35px;
      }
      .img-logo1{
        width: 15vw;
        height: 24px;
      }
      // background-color: #ccc;
      // a {
      //   img {
      //     width: 100%;
      //   }
      // }
    }

    .menu {
      margin-left: 186px;

      ul {
        display: flex;

        li {
          width: 95px;
          height: 60px;
          line-height: 60px;
          font-size: 12px;
          text-align: center;
          position: relative;
          transform: skewX(-30deg);

          div {
            transform: skewX(30deg);
          }
        }

        .active {
          background-image: linear-gradient(to bottom, #fff, #5049ef, #fff);
          color: #fff;
        }
      }
    }

    .right {
      width: 12px;
      height: 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      span {
        display: block;
        width: 100%;
        height: 2px;
        background-color: #5049ef;
      }
    }
  }
}
</style>
